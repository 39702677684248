import React from 'react';
import './Modal.css'; 

function Modal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-card">
        <h2>Thank You!</h2>
        <p>{message}</p>
        <button className="modal-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default Modal;
