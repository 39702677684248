import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from '../../components/ThankyouModal/Modal';
import "./SecondPage.css";

function SecondPage() {
  const [specialization, setSpecialization] = useState("");
  const [claims, setClaims] = useState("");
  const [sumInsured, setSumInsured] = useState("");
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleContinue = () => {
    if (specialization === '' || claims === '' || sumInsured === '') {
      setError(true);
    } else {
      setError(false);

      // Retrieve existing form data from localStorage
      const existingFormData = JSON.parse(localStorage.getItem("formData")) || {};

      // Update the form data with the new values from SecondPage
      const updatedFormData = {
        ...existingFormData,
        specialization,
        claims,
        sumInsured,
      };

      // Store the updated form data back in localStorage
      localStorage.setItem("formData", JSON.stringify(updatedFormData));

      // Handle navigation based on claims selection
      if (claims === 'Yes') {
        setShowModal(true);
      } else if (claims === 'No') {
        navigate('/third');
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate('/second');
  };

  return (
    <section className="flex flex-col md:flex-row justify-center  h-[calc(100vh-60px)]">
      {/* Left Form Section */}
      <article className="md:w-1/2 p-8 h-full bg-white flex flex-col justify-center relative">
        {/* Yellow Overlay */}
        <img
          src={require("../../assets/app/doctors2.png")}
          alt="Background"
          className="md:hidden absolute inset-0 w-full h-full object-cover opacity-15"
        />

        <div className="space-y-10 z-50">
          <div className="block lg:flex lg:space-x-4">
            <div className="w-full lg:w-1/2 mb-6">
              <label className="block text-md font-normal">
                Professional Specialisation{" "}
                <span className="text-red-500">*</span>
              </label>
              <select
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
                className="mt-2 block w-full border-b border-amber-400 focus:outline-none focus:border-amber-600 p-2 bg-transparent text-gray-600"
              >
                <option value="">Select</option>
                <option value="Doctor">Doctor</option>
                <option value="Surgeon">Surgeon</option>
                <option value="Dentist">Dentist</option>
              </select>
            </div>

            <div className="w-full lg:w-1/2 mb-5">
              <label className="block text-md font-normal">
                Sum Insured <span className="text-red-500">*</span>
              </label>
              <select
                value={sumInsured}
                onChange={(e) => setSumInsured(e.target.value)}
                className="mt-2 block w-full border-b border-amber-400 focus:outline-none focus:border-amber-600 p-2 bg-transparent text-gray-600"
              >
                <option value="">Select</option>
                <option value="500000">₹500,000</option>
                <option value="1000000">₹1,000,000</option>
                <option value="2000000">₹2,000,000</option>
              </select>
            </div>
          </div>

          <div className="block items-center space-x-6">
            <label className="text-md font-normal">
              Any claims in the last 1 year?
            </label>
            <div className="cutom-radio-left-margin flex items-center space-x-4 mt-3">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="claims"
                  value="Yes"
                  checked={claims === "Yes"}
                  onChange={(e) => setClaims(e.target.value)}
                  className="custom-radio form-radio h-4 w-4 text-black border-gray-300 focus:ring-0 cursor-pointer"
                />
                <span className="text-md">Yes</span>
              </label>

              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="claims"
                  value="No"
                  checked={claims === "No"}
                  onChange={(e) => setClaims(e.target.value)}
                  className="custom-radio form-radio h-4 w-4 text-black border-gray-300 focus:ring-0 cursor-pointer"
                />
                <span className="text-md">No</span>
              </label>
            </div>
          </div>

          <button
            className="mt-6 px-5 float-right w-max bg-black text-white py-2 rounded-sm hover:bg-neutral-800"
            onClick={handleContinue}
          >
            Continue
          </button>
          {error && (
            <p className="text-red-600 mt-4 text-sm">
              Please fill out all required fields.
            </p>
          )}
        </div>
      </article>

      {/* Right Content Section */}
      <article className="hidden md:block md:w-1/2 bg-cover bg-center relative">
        <img
          src={require("../../assets/app/doctors2.png")}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover opacity-50"
        />
        <div className="absolute inset-1 bg-[#fee5995b]"></div>{" "}
        <div className="relative z-10 flex flex-col justify-start mt-24 text-left text-neutral-700 px-8">
          <h1 className="text-lg lg:text-2xl text-neutral-700 font-bold leading-tight self-center">
            Instant Coverage. Zero Hassle. <br />
            Secure your Professional Indemnity <br /> in less than 5 minutes.
          </h1>
          <h4 className="text-xs text-neutral-800 lg:text-md mt-4 self-center font-bold">
            Safeguard your career with reliable protection.
          </h4>
        </div>
      </article>

      {showModal && (
        <Modal
          message="Your claim has been recorded successfully. Thank you for securing your professional indemnity!"
          onClose={closeModal} 
        />
      )}
    </section>
  );
}

export default SecondPage;
