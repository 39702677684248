// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Inter", sans-serif;
}

/* body {
  @apply scrollbar-hide;
} */

/* Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* Firefox */
body {
  scrollbar-width: none; /* Hides the scrollbar */
}

/* IE and Edge */
body {
  -ms-overflow-style: none; /* Hides the scrollbar */
}
`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;;GAEG;;AAEH,qCAAqC;AACrC;EACE,aAAa,EAAE,wBAAwB;AACzC;;AAEA,YAAY;AACZ;EACE,qBAAqB,EAAE,wBAAwB;AACjD;;AAEA,gBAAgB;AAChB;EACE,wBAAwB,EAAE,wBAAwB;AACpD","sourcesContent":["* {\n  font-family: \"Inter\", sans-serif;\n}\n\n/* body {\n  @apply scrollbar-hide;\n} */\n\n/* Webkit browsers (Chrome, Safari) */\n::-webkit-scrollbar {\n  display: none; /* Hides the scrollbar */\n}\n\n/* Firefox */\nbody {\n  scrollbar-width: none; /* Hides the scrollbar */\n}\n\n/* IE and Edge */\nbody {\n  -ms-overflow-style: none; /* Hides the scrollbar */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
