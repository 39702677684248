// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    font-family: "Inter", sans-serif;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(5, 5, 5, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  
  .modal-card {
    height: 40%;
    width: 90%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    color: rgb(58, 57, 57);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    font-family: "Inter", sans-serif;
  }
  
  .modal-button {
    margin-top: 20%;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ThankyouModal/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gCAAgC;IAChC,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;;EAGA;IACE,WAAW;IACX,UAAU;IACV,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,2CAA2C;IAC3C,gCAAgC;EAClC;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".modal-overlay {\n    position: fixed;\n    font-family: \"Inter\", sans-serif;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(5, 5, 5, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  \n  .modal-card {\n    height: 40%;\n    width: 90%;\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    text-align: center;\n    width: 300px;\n    color: rgb(58, 57, 57);\n    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n    font-family: \"Inter\", sans-serif;\n  }\n  \n  .modal-button {\n    margin-top: 20%;\n    padding: 10px 20px;\n    background-color: black;\n    color: white;\n    border: none;\n    cursor: pointer;\n  }\n  \n  .modal-button:hover {\n    background-color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
