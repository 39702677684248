// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* * {
  font-family: "Inter", sans-serif;
} */

.custom-radio {
  accent-color: rgba(203, 123, 52, 0.89);
}

.cutom-radio-left-margin{
    margin-left: 0 !important;
  
}`, "",{"version":3,"sources":["webpack://./src/pages/SecondPage/SecondPage.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,sCAAsC;AACxC;;AAEA;IACI,yBAAyB;;AAE7B","sourcesContent":["/* * {\n  font-family: \"Inter\", sans-serif;\n} */\n\n.custom-radio {\n  accent-color: rgba(203, 123, 52, 0.89);\n}\n\n.cutom-radio-left-margin{\n    margin-left: 0 !important;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
