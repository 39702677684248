import React, { useState, useEffect } from "react";
import "./FrontPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function FrontPage() {
  const navigate = useNavigate();

  const [pinVerified, setPinVerified] = useState(false);

  // State to track form inputs and errors
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    pincode: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    pincode: "",
  });

  // Handle input changes and update state
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      setPinVerified(false);
    }
    setFormData({ ...formData, [name]: value });
  };

  const validatePincodeAPI = async (pincode) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      const data = response.data[0];
      if (data.Status === "Success") {
        setPinVerified(true); // Set pinVerified to true
        return true;
      } else {
        setPinVerified(false);
        return false;
      }
    } catch (error) {
      console.error("Error validating pincode", error);
      setPinVerified(false);
      return false;
    }
  };

  // Validation logic
  const validateForm = () => {
    let valid = true;
    let newErrors = { name: "", mobile: "", email: "", pincode: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number must contain exactly 10 digits.";
      valid = false;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      valid = false;
    }

    if (!/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Pincode must contain exactly 6 digits.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Store form data in localStorage
      localStorage.setItem("formData", JSON.stringify(formData));

      // Navigate to the next page
      navigate("/second");
    }
  };

  // Live validation for mobile and pincode
  useEffect(() => {
    const newErrors = { ...errors };

    if (/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = ""; // Clear error if mobile is valid
    } else if (formData.mobile.length > 0 && formData.mobile.length < 10) {
      newErrors.mobile = "Mobile number must contain exactly 10 digits.";
    }

    if (/^\d{6}$/.test(formData.pincode)) {
      // If pincode is 6 digits, validate via API
      validatePincodeAPI(formData.pincode).then((isValid) => {
        if (isValid) {
          newErrors.pincode = ""; // Clear error if pincode is valid
        } else {
          newErrors.pincode = "Please enter a valid pincode.";
        }
        setErrors(newErrors);
      });
    } else if (formData.pincode.length > 0 && formData.pincode.length < 6) {
      newErrors.pincode = "Pincode must contain exactly 6 digits.";
      setErrors(newErrors);
    } else {
      newErrors.pincode = ""; // Clear error if no pincode entered or valid
      setErrors(newErrors);
    }
  }, [formData.mobile, formData.pincode]);

  return (
    <section id="MainSection">
      <article id="MainArticle">
        <div id="FirstDiv">
          <h1 id="H1heading">
            Protect Your Practice: Get <br /> Quick, Personalized Professional
            Indemnity <br /> Insurance Today!
          </h1>
          <h4 id="H4heading">
            Coverage designed for doctors, ensuring peace of mind when it
            matters.
          </h4>
        </div>

        <div id="SecondDiv">
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <label>
              Name of insured <span className="required">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter Your Name"
              className={errors.name ? "error-border" : ""}
              required
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}

            {/* Mobile Number Field */}
            <label>
              Mobile Number <span className="required">*</span>
            </label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              maxLength="10"
              placeholder="Enter mobile number"
              className={errors.mobile ? "error-border" : "valid-border"} // Valid border for correct input
              required
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                  e.preventDefault();
                }
              }}
            />
            {errors.mobile && (
              <p className="text-red-500 text-xs">{errors.mobile}</p>
            )}

            {/* Email Field */}
            <label>
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Your Email"
              className={errors.email ? "error-border" : ""}
              required
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}

            {/* Pincode Field */}
            <label>
              Pincode <span className="required">*</span>
            </label>

            <input
              type="number"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              placeholder="Enter Pincode"
              className={errors.pincode ? "error-border" : "valid-border"} // Valid border for correct input
              required
              onInput={(e) => {
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6);
                }
              }}
              onWheel={(e) => e.target.blur()} // Prevents number scroll
            />

            {pinVerified && (
              <div className="flex items-center mt-2 text-xs text-green-600 font-semibold">
                <div className="w-5 h-5 text-lg rounded-full bg-green-600 text-white flex items-center justify-center mr-1">
                  &#10003;
                </div>
                <span>PIN Verified</span>
              </div>
            )}

            {errors.pincode && (
              <p className="text-red-500 text-xs">{errors.pincode}</p>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-black text-white font-light text-sm py-2 px-4 rounded-sm hover:bg-neutral-800"
            >
              Save & Continue
            </button>
          </form>
        </div>
      </article>
    </section>
  );
}

export default FrontPage;
