// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navbar{
    height: 60px;
    width: 100%;
    font-family: "Inter", sans-serif;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
img{
    height: 120%;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav/Nav.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,gCAAgC;AACpC;AACA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,YAAY;AAChB","sourcesContent":["#navbar{\n    height: 60px;\n    width: 100%;\n    font-family: \"Inter\", sans-serif;\n}\n*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\nimg{\n    height: 120%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
