import React, { useEffect, useState } from "react";
import EditFormModal from "../../components/FormModal/EditFormModal";
import "./ThirdPage.css";
import sortAscIcon from "../../assets/icon/sort-asc.svg";

function ThirdPage() {
  const [formData, setFormData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // JSON Data moved into the component
  const partnersData = [
    {
      id: 1,
      name: "Icici",
      image: require("../../assets/partners/icici.png"),
      policyType: "Currently Set for Lowest",
      currentPrice: 8880,
      buyPrice: 5190,
    },
    {
      id: 2,
      name: "Bajaj",
      image: require("../../assets/partners/bajaj.png"),
      policyType: "Premium Coverage",
      currentPrice: 10200,
      buyPrice: 6800,
    },
    {
      id: 2,
      name: "Sbi",
      image: require("../../assets/partners/sbi.png"),
      policyType: "Premium Coverage",
      currentPrice: 12200,
      buyPrice: 7800,
    },
    {
      id: 4,
      name: "Hdfc",
      image: require("../../assets/partners/hdfc.png"),
      policyType: "Premium Coverage",
      currentPrice: 9200,
      buyPrice: 5800,
    },
    {
      id: 5,
      name: "Chola",
      image: require("../../assets/partners/chola.png"),
      policyType: "Premium Coverage",
      currentPrice: 11300,
      buyPrice: 9800,
    },
    {
      id: 6,
      name: "Tata",
      image: require("../../assets/partners/tata.png"),
      policyType: "Premium Coverage",
      currentPrice: 15300,
      buyPrice: 12400,
    },
  ];

  useEffect(() => {
    // Retrieve the form data from localStorage
    const storedData = JSON.parse(localStorage.getItem("formData"));
    if (storedData) {
      setFormData(storedData);
    }
    setPartners(partnersData);
  }, []);

  const handleEditClick = () => {
    setIsModalOpen(true); // Open the modal when the "Edit" button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleFormSubmit = (updatedData) => {
    // Update the form data in localStorage and state
    localStorage.setItem("formData", JSON.stringify(updatedData));
    setFormData(updatedData);
  };

  // Function to sort partners in ascending order based on currentPrice
  const handleSort = () => {
    setIsLoading(true);
    setTimeout(() => {
      const sortedPartners = [...partners].sort(
        (a, b) => a.currentPrice - b.currentPrice
      );
      setPartners(sortedPartners);
      setIsLoading(false); // End loading after sorting
    }, 3000); // 3 seconds delay
  };

  return (
    <section className="flex flex-col md:flex-row justify-center bg-slate-100 ">
      {/* First Article - Display stored data */}
      <article className="flex-col w-full md:w-1/3 p-8 shadow-lg rounded-md">
        <h2 className="text-2xl font-bold mb-6">
          Professional Indemnity policy <br /> for Doctors & Medical <br />{" "}
          Practitioners
        </h2>
        <div className="h-max md:w-[98%] p-5 border border-amber-400 bg-[#faf1d65b] shadow-xl rounded-sm flex justify-center items-center flex-col">
          <ul className="space-y-4 text-md text-gray-800">
            <li>
              <strong>Name:</strong> {formData.name || "N/A"}
            </li>
            <li>
              <strong>Mobile Number:</strong> {formData.mobile || "N/A"}
            </li>
            <li>
              <strong>Email:</strong> {formData.email || "N/A"}
            </li>
            <li>
              <strong>Pincode:</strong> {formData.pincode || "N/A"}
            </li>
            <li>
              <strong>Specialization:</strong>{" "}
              {formData.specialization || "N/A"}
            </li>
            <li>
              <strong>Sum Insured:</strong>{" "}
              {formData.sumInsured ? `₹${formData.sumInsured}` : "N/A"}
            </li>
          </ul>
          <button
            className="bg-black text-white h-8 w-24 mt-6"
            onClick={handleEditClick}
          >
            Edit
          </button>
        </div>
      </article>

      {/* Modal for editing the form data */}
      {isModalOpen && (
        <EditFormModal
          formData={formData}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
        />
      )}

      {/* Second Article - Display partner data from JSON */}
      <article className="md:w-2/3 p-8 h-full flex flex-col relative">
        <header className="flex justify-evenly sticky top-0 bg-amber-100 z-10 shadow-lg p-2 text-xs font-semibold">
          <h2 className="text-center w-1/3">Insurer</h2>
          <h2 className="text-center w-1/3">Base Plane</h2>
          <div className="flex items-center justify-center w-1/3">
            <h2 className="text-center">Price</h2>
            {/* Sorting Icon (using react-icons or a custom SVG) */}
            <img
              src={sortAscIcon}
              alt="Sort Ascending"
              className="h-6 w-6 ml-3 text-gray-700 cursor-pointer"
              onClick={handleSort}
            />
          </div>
        </header>
        <div className="overflow-y-auto h-[calc(100vh-100px)] scrollbar-hide">
          {isLoading ? ( // Show loading state
            <div className="h-20 p-3 flex items-center justify-between space-x-6 border mt-3 shadow-md mb-4">
              <div className="h-full w-1/3 flex justify-center items-center">
                <div className="w-3/4 h-3/4 bg-amber-100 rounded-md animate-pulse"></div>
              </div>
              <div className="h-full w-1/3 flex flex-col justify-center items-center space-y-3">
                <div className="w-full h-2 bg-amber-100 rounded-full animate-pulse"></div>
                <div className="w-1/2 h-2 bg-amber-100 rounded-full animate-pulse"></div>
              </div>
              <div className="h-full w-1/3 flex items-center justify-center">
                <div className="w-2/3 h-10 bg-amber-100 rounded-sm animate-pulse"></div>
              </div>
            </div>

          ) : (
            partners.map((partner) => (
              <div
                key={partner.id}
                className="h-20 p-5 flex items-center justify-between border mt-3 shadow-md mb-4"
              >
                <div className="h-full w-1/3 flex justify-center items-center">
                  <img
                    src={partner.image}
                    alt={partner.name}
                    className="max-h-24 object-contain"
                  />
                </div>
                <div className="h-full w-1/3 text-center flex flex-col justify-center">
                  <h4 className="font-bold text-xs">{partner.policyType}</h4>
                  <h3 className="font-bold text-xs text-gray-600">
                    ₹{partner.currentPrice}/-
                  </h3>
                </div>
                <div className="h-full w-1/3 flex items-center justify-center ml-5">
                  <button className="bg-black text-xs p-2 w-max text-white h-10">
                    Buy For 1 Yr ₹{partner.buyPrice}/-
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </article>
    </section>
  );
}
export default ThirdPage;

/* <article className="w-full p-8 h-full flex flex-col">
        <div className="overflow-y-auto h-72">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-amber-100">
                <th className="p-3 border-b text-left font-semibold">
                  Partner
                </th>
                <th className="p-3 border-b text-center font-semibold">
                  Current Price
                </th>
                <th className="p-3 border-b text-center font-semibold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white hover:bg-slate-100 shadow-lg">
                <td className="p-3 border-b">
                  <img src={Icici} alt="Icici Partner" width={120} />
                </td>
                <td className="p-3 border-b text-center">
                  <h4 className="font-bold text-xs">
                    Currently Set for Lowest
                  </h4>
                  <h3>8880/-</h3>
                </td>
                <td className="p-3 border-b text-center">
                  <button className="bg-black text-xs p-2 text-white h-10 w-max">
                    Buy For 1 Yr 5190/-
                  </button>
                </td>
              </tr>
              <tr className="bg-white hover:bg-slate-100">
                <td className="p-3 border-b">
                  <img src={Bajaj} alt="Bajaj Partner" width={120} />
                </td>
                <td className="p-3 border-b text-center">
                  <h4 className="font-bold text-xs">
                    Currently Set for Lowest
                  </h4>
                  <h3>7500/-</h3>
                </td>
                <td className="p-3 border-b text-center">
                  <button className="bg-black text-xs p-2 text-white h-10 w-max">
                    Buy For 1 Yr 4900/-
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article> */
