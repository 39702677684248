import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/app/brand.png";
import "./Nav.css";

function NavBar() {
  return (
    <div>
      <nav
        id="navbar"
        className=" bg-black flex justify-center items-center  shadow-2xg shadow-blue-500/50  font-bold"
      >
        <section className="h-20 w-11/12 flex justify-between items-center">
          <Link to="/">
            <div className="flex justify-center items-center">
              <img src={logo} alt="Logo" className=" w-48 -mt-1" />
            </div>
          </Link>
          <div className="h-2/3 w-1/12  flex justify-center items-center">
            <button className="border-0 bg-amber-500 border-amber-300 px-5 py-1 rounded-sm font-normal transparent text-sm text-black hover:shadow-lg hover:bg-amber-300 duration-300 mr-5">
              Claim
            </button>
          </div>
        </section>
      </nav>
    </div>
  );
}

export default NavBar;
