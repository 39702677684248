// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: "Inter", sans-serif;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: "Inter", sans-serif;
  
  }
  
  .modal-content {
    /* height: 50vh; */
    /* width: 70%; */
    background-color: white;
    padding-top:30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    /* padding-right: 20%; */
    border-radius: 8px;
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    font-family: "Inter", sans-serif;

  
  }
  
  .close-btn {
    background-color: red;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10%;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ThirdPage/ThirdPage.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;EAClC;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,gCAAgC;;EAElC;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,sBAAsB;IACtB,gCAAgC;;;EAGlC;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB","sourcesContent":["* {\n    font-family: \"Inter\", sans-serif;\n  }\n  \n  .modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    font-family: \"Inter\", sans-serif;\n  \n  }\n  \n  .modal-content {\n    /* height: 50vh; */\n    /* width: 70%; */\n    background-color: white;\n    padding-top:30px;\n    padding-bottom: 30px;\n    padding-left: 30px;\n    padding-right: 30px;\n    /* padding-right: 20%; */\n    border-radius: 8px;\n    max-width: 700px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    flex-direction: column;\n    font-family: \"Inter\", sans-serif;\n\n  \n  }\n  \n  .close-btn {\n    background-color: red;\n    color: white;\n    padding: 10px 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-left: 10%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
