import Footer from "./components/Footer/Footer";
import NavBar from "./components/Nav/NavBar";
import FrontPage from "./pages/FrontPage/FrontPage";
import SecondPage from "./pages/SecondPage/SecondPage";
import ThirdPage from "./pages/ThirdPage/ThirdPage";
import "./app.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/second" element={<SecondPage />} />
        <Route path="/third" element={<ThirdPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

// font-family: "Cinzel", serif;
// font-family: "Roboto Slab", serif;
