import React from "react";

const Footer = () => {
  return (
    <div className="h-max text-white text-xs py-4 px-12 text-center bg-black flex justify-center items-center ">
      <p>
        © 2024 Copyright{" "}
        <span className="font-bold">
          Mutual Global Insurance Broking Private Limited
        </span>{" "}
        All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
