import React, { useState } from 'react';

function EditFormModal({ formData, onClose, onSubmit }) {
  const [formValues, setFormValues] = useState(formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues); // Pass the updated form values back to the parent
    onClose(); // Close the modal after submission
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-sm shadow-lg p-4 w-full max-w-lg relative">

        {/* Cross Button in the Top-Right Corner */}
        <button
          onClick={onClose}
          className="absolute top-2 right-3 text-black text-3xl font-normal  hover:text-red-500 transition-colors"
        >
          &times;
        </button>

        <h2 className="text-2xl font-bold mb-4">Edit Your Information</h2>

        <form onSubmit={handleSubmit} className="space-y-2">
          {/* Name Field */}
          <div className="flex flex-col w-full">
            <label className="text-gray-800 font-medium mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              className="border-b border-amber-400 px-2 py-2 focus:outline-none"
              required
            />
          </div>

          {/* Mobile Number Field */}
          <div className="flex flex-col w-full">
            <label className="text-gray-800 font-medium mb-1">Mobile Number:</label>
            <input
              type="tel"
              name="mobile"
              value={formValues.mobile}
              onChange={handleInputChange}
              maxLength="10"
              className="border-b border-amber-400 px-4 py-2 focus:outline-none"
              required
            />
          </div>

          {/* Email Field */}
          <div className="flex flex-col">
            <label className="text-gray-800 font-medium mb-1">Email:</label>
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              className="border-b border-amber-400 px-4 py-2 focus:outline-none w-full"
              required
            />
          </div>

          {/* Pincode Field */}
          <div className="flex flex-col">
            <label className="text-gray-800 font-medium mb-1">Pincode:</label>
            <input
              type="text"
              name="pincode"
              value={formValues.pincode}
              onChange={handleInputChange}
              maxLength="6"
              className="border-b border-amber-400 px-4 py-2 focus:outline-none w-full"
              required
            />
          </div>

          {/* Specialization Field */}
          <div className="flex flex-col">
            <label className="text-gray-800 font-medium mb-1">Specialization:</label>
            <select
              name="specialization"
              value={formValues.specialization}
              onChange={handleInputChange}
              className="border border-amber-400 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-400"
              required
            >
              <option value="Doctor">Doctor</option>
              <option value="Surgeon">Surgeon</option>
              <option value="Dentist">Dentist</option>
            </select>
          </div>

          {/* Sum Insured Field */}
          <div className="flex flex-col">
            <label className="text-gray-800 font-medium mb-1">Sum Insured:</label>
            <select
              name="sumInsured"
              value={formValues.sumInsured}
              onChange={handleInputChange}
              className="border border-amber-400 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-amber-400"
              required
            >
              <option value="500000">₹500,000</option>
              <option value="1000000">₹1,000,000</option>
              <option value="2000000">₹2,000,000</option>
            </select>
          </div>

          {/* Save Button */}
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-black text-white px-4 py-2 ml-12 rounded-md hover:bg-gray-800"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditFormModal;
